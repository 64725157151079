/* eslint-disable import/no-cycle */
import axios from 'app/client';
import _ from '@lodash';
import { arrayify, getJntUrl, responseErrors } from 'app/utils/helpers';
import { getSelectedLicenseGroupId, getJobNames, getProfile } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { JobName, JobNameGroup } from 'app/store/types';
import { UNGROUPED_NAMING_GROUP_ID } from 'app/utils/default-groups';
import { string } from 'prop-types';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

const findErrors = (res: any) => {
	const err: any = [];
	arrayify(res).forEach((response: any) => {
		if (Array.isArray(response.data)) {
			response.data.forEach((datum: any) => {
				if (datum.type) {
					err.push(datum.type);
				}
			});
		}
	});
	return err;
};

const errors = [
	'job-name-template-delete-failed',
	'unexpected-error',
	'unauthorized',
	'not found',
	'already-assigned',
	'already-removed',
	'update-job-name-template-group-failed',
	'not found'
];

export const createTemplate = (name: string, userId: string, description: string, components: any): AppThunk => async (
	dispatch,
	getState
) => {
	const profile = getProfile(getState());
	const jntApiUrl = getJntUrl(profile.awsRegion);
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	const data = {
		name,
		// description,
		userId,
		components
	};

	try {
		const response = await axios.post(`${jntApiUrl}/api/portal/jnt/${licenseGroupId}`, data);

		const err: any = findErrors(response);
		console.log(responseErrors(response).length);
		console.log(err);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['job names'], () => {
				if (err.length > 0) {
					dispatch(appActions.alert('template:create:fail', 'warning'));
				} else if (response.data.status === 'failed') {
					dispatch(appActions.alert('template:create:fail', 'warning'));
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('template:create:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('template:create:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'template:create:fail'));
	}
};

export const cloneTemplate = (id: string): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfile(getState());
	const jntApiUrl = getJntUrl(profile.awsRegion);
	const jobNames = getJobNames(getState());

	const data = {
		name: `${jobNames.find(jobName => jobName.id === id)?.name}-Copy`,
		userId: profile.id,
		components: jobNames.find(jobName => jobName.id === id)?.components
	};

	try {
		const response = await axios.post(`${jntApiUrl}/api/portal/jnt/${licenseGroupId}`, data);

		const err: any = findErrors(response);
		console.log(responseErrors(response).length);
		console.log(err);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['job names'], () => {
				if (err.length > 0) {
					dispatch(appActions.alert('template:clone:fail', 'warning'));
				} else if (response.data.status === 'failed') {
					dispatch(appActions.alert('template:clone:fail', 'warning'));
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('template:clone:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('template:clone:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'template:clone:fail'));
	}
};

export const editTemplate = (id: string, name: string, description: string, components: any): AppThunk => async (
	dispatch,
	getState
) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfile(getState());
	const jntApiUrl = getJntUrl(profile.awsRegion);
	const jobNames = getJobNames(getState());

	const data = {
		id,
		name,
		// description,
		userId: profile.id,
		components
	};

	try {
		const response = await axios.post(`${jntApiUrl}/api/portal/jnt/${licenseGroupId}`, data);

		const err: any = findErrors(response);
		console.log(responseErrors(response).length);
		console.log(err);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['job names'], () => {
				if (err.length > 0) {
					dispatch(appActions.alert('template:edit:fail', 'warning'));
				} else if (response.data.status === 'failed') {
					dispatch(appActions.alert('template:edit:fail', 'warning'));
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('template:edit:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('template:edit:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'template:edit:fail'));
	}
};

export const removeTemplate = (id: string): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfile(getState());
	const jntApiUrl = getJntUrl(profile.awsRegion);

	const data = {
		delete: [id]
	};

	try {
		const response = await axios.patch(
			`${jntApiUrl}/api/portal/jnt/${licenseGroupId}/remove-job-name-template`,
			data
		);

		const err: any = findErrors(response);
		console.log(responseErrors(response).length);
		console.log(err);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['job names'], () => {
				if (err.length > 0) {
					// change error code
					if (err.includes('update-job-name-template-group-failed')) {
						// change color
						dispatch(appActions.alert('template:delete:fail', 'warning'));
						// change error code
					} else if (err.includes('unexpected-error')) {
						dispatch(appActions.alert('template:delete:fail', 'warning'));
					} else if (err.includes('unauthorized')) {
						dispatch(appActions.alert('template:delete:fail', 'warning'));
					} else if (err.includes('not found')) {
						dispatch(appActions.alert('template:delete:fail', 'warning'));
					} else if (err.includes('already-removed')) {
						dispatch(appActions.alert('template:delete:fail', 'warning'));
					} else if (err.includes('already-assigned')) {
						dispatch(appActions.alert('template:delete:fail', 'warning'));
					}
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('template:delete:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('template:delete:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'template:delete:fail'));
	}
};

export const moveTemplatesToTemplateGroups = (payload: any): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfile(getState());
	const jntApiUrl = getJntUrl(profile.awsRegion);

	const data = {
		jobNameTemplates: payload.jobNames
	};

	try {
		const response = await axios.patch(
			`${jntApiUrl}/api/portal/jnt/${licenseGroupId}/job-name-template-group`,
			data
		);

		const err: any = findErrors(response);
		console.log(responseErrors(response).length);
		console.log(err);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['job names'], () => {
				if (err.length > 0) {
					// change error code
					if (err.includes('job-name-template-delete-failed')) {
						// change color
						dispatch(appActions.alert('template:move:fail', 'warning'));
						// change error code
					} else if (err.includes('unexpected-error')) {
						dispatch(appActions.alert('template:move:fail', 'warning'));
					} else if (err.includes('not found')) {
						dispatch(appActions.alert('template:move:fail', 'warning'));
					}
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('template:move:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('template:move:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'template:move:fail'));
	}
};

export const removeTemplatesFromAllTemplateGroups = (ids: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfile(getState());
	const jntApiUrl = getJntUrl(profile.awsRegion);
	const state = getState();
	const jobNames = getJobNames(state) || [];
	const groups = new Set();

	const payload = {
		jobNameTemplates: jobNames
			.filter(jobName => ids.includes(jobName.id))
			.map(jobName => {
				jobName.groupList.forEach(id => {
					groups.add(id);
				});
				return { id: jobName.id, remove: jobName.groupList };
			})
	};

	const data = {
		jobNameTemplates: payload.jobNameTemplates
	};

	try {
		const response = await axios.patch(
			`${jntApiUrl}/api/portal/jnt/${licenseGroupId}/job-name-template-group`,
			data
		);

		const err: any = findErrors(response);
		console.log(responseErrors(response).length);
		console.log(err);
		dispatch(
			licenseGroupsActions.getSelectedLicenseGroupData(['job names'], () => {
				if (err.length > 0) {
					// change error code
					if (err.includes('job-name-template-delete-failed')) {
						// change color
						dispatch(appActions.alert('template:move:fail', 'warning'));
						// change error code
					} else if (err.includes('unexpected-error')) {
						dispatch(appActions.alert('template:move:fail', 'warning'));
					}
				} else if (responseErrors(response).length || _.intersection(err, errors).length > 0) {
					dispatch(appActions.alert('template:move:fail', 'warning'));
				} else if (err.length === 0) {
					dispatch(appActions.alert('template:move:success', 'success'));
				}
			})
		);
	} catch (error) {
		dispatch(appActions.handleError(error, 'template:move:fail'));
	}
};
